.customTextField {
    gap: 10px;
    padding: 0;
    white-space: pre-line;
    color: #e0e0e0;
}

.chip {
    border: 1px solid #404040;
    border-radius: 4px;
    padding: 4px 8px;
    margin-right: 8px;
    margin-bottom: 8px;
    align-items: center;
    color: #ffffff;
    position: relative;
    display: inline-block;
    transition: all 0.2s ease-in-out;
}

.chip:hover {
    filter: brightness(1.1);
    transform: translateY(-1px);
}

.deleteBtn {
    color: #ffffff;
    margin-left: 8px;
    opacity: 0.8;
}

.deleteBtn:hover {
    opacity: 1;
}

.input {
    border: none;
    outline: none;
    background-color: transparent;
    color: #ffffff;
    font-size: 14px;
    padding: 2px 4px;
    width: 100%;
}

.element {
    margin: 8px 0;
}

.sectionLabel {
    display: inline-block;
    color: #b0b0b0;
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 500;
}

.sectionLabel::first-letter {
    text-transform: uppercase;
}

[contenteditable="true"]:empty:before {
    content: attr(placeholder);
    pointer-events: none;
    color: #808080;
}

.editableContent {
    background-color: #2c2c2c;
    color: #e0e0e0;
    line-height: 1.6;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    white-space: pre-line;
    min-height: 200px;
    padding: 20px;
    border: 1px solid #404040;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
    position: relative;
}

.editableContent:focus {
    outline: none;
    border-color: #1976d2;
    box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.2);
}

.editableContent:empty::before {
    content: attr(placeholder);
    color: #808080;
    position: absolute;
    pointer-events: none;
    cursor: text;
    opacity: 1;
    left: 20px;
    top: 20px;
}

.editableContent:not(:empty)::before {
    opacity: 0;
}

.sectionWrapper {
    display: block;
    margin-top: 16px;
    padding: 16px 20px;
    border-top: 1px solid #404040;
    background-color: #2c2c2c;
    border: 1px solid #404040;
    border-radius: 4px;
    margin-bottom: 8px;
}
