.img {
    height: 52px;
    margin-right: 10px;
    display: flex;
    align-items: center;
}
.searchInputBox {
    width: 300px;
    padding: 10px;
}

