.inputBox {
    width: 100%;
}

.inputBox :global(.MuiOutlinedInput-root) {
    background-color: #2c2c2c;
    color: #e0e0e0;
    font-size: 1rem;
    border-radius: 12px;
    transition: all 0.2s ease-in-out;
    min-height: 80px;
    padding: 0;
    display: flex;
    align-items: stretch;
    overflow: hidden;
}

.inputBox :global(.MuiOutlinedInput-root fieldset) {
    border-color: #404040;
    border-width: 1px;
    border-radius: 12px;
}

.inputBox :global(.MuiOutlinedInput-root:hover fieldset) {
    border-color: #666666;
}

.inputBox :global(.MuiOutlinedInput-root.Mui-focused fieldset) {
    border-color: #1976d2;
    border-width: 2px;
}

.inputBox :global(.MuiInputBase-input) {
    padding: 20px !important;
    min-height: 80px !important;
    height: 100% !important;
    display: block !important;
    white-space: pre-wrap !important;
    overflow-y: auto !important;
    line-height: 1.5 !important;
    cursor: text;
    margin: 0 !important;
    align-self: stretch;
    box-sizing: border-box;
}

.inputBox :global(.MuiAutocomplete-root) {
    width: 100%;
}

.inputBox :global(.MuiInputBase-root) {
    padding: 0 !important;
    height: auto !important;
    min-height: 80px;
    align-items: stretch;
    border-radius: 12px;
}

.inputBox :global(.MuiInputBase-input::placeholder) {
    color: #808080;
    opacity: 1;
    line-height: 1.5;
}

.inputBox :global(.MuiAutocomplete-endAdornment) {
    position: absolute;
    top: 20px;
    right: 20px;
}

/* Dropdown Popper styling */
.inputBox :global(.MuiAutocomplete-popper) {
    margin-top: 8px !important;
}

.inputBox :global(.MuiAutocomplete-paper) {
    background-color: #2c2c2c;
    border: 1px solid #404040;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.inputBox :global(.MuiAutocomplete-listbox) {
    padding: 8px 0;
    background-color: #2c2c2c;
    color: #e0e0e0;
    max-height: 400px !important;
    overflow-y: auto !important;
}

/* Hide default scrollbar for Firefox */
.inputBox :global(.MuiAutocomplete-listbox) {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

/* Custom scrollbar styling */
.inputBox :global(.MuiAutocomplete-listbox::-webkit-scrollbar) {
    width: 16px;
    background-color: transparent;
}

.inputBox :global(.MuiAutocomplete-listbox::-webkit-scrollbar-track) {
    background-color: transparent;
    margin: 4px;
}

.inputBox :global(.MuiAutocomplete-listbox::-webkit-scrollbar-thumb) {
    background-color: #666666;
    border: 4px solid #2c2c2c;
    border-radius: 8px;
    min-height: 40px;
}

.inputBox :global(.MuiAutocomplete-listbox::-webkit-scrollbar-thumb:hover) {
    background-color: #808080;
}

/* Group Label styling */
.inputBox :global(.MuiAutocomplete-groupLabel) {
    background-color: #1e1e1e;
    color: #b0b0b0;
    padding: 12px 20px;
    font-size: 0.85rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    border-bottom: 1px solid #404040;
    position: sticky;
    top: 0;
    z-index: 1;
}

/* Option styling */
.inputBox :global(.MuiAutocomplete-option) {
    padding: 12px 20px !important;
    min-height: 48px !important;
    font-size: 0.95rem !important;
    color: #e0e0e0 !important;
    transition: all 0.2s ease-in-out !important;
    border-bottom: 1px solid #404040;
}

.inputBox :global(.MuiAutocomplete-option:last-child) {
    border-bottom: none;
}

.inputBox :global(.MuiAutocomplete-option[aria-selected="true"]) {
    background-color: rgba(25, 118, 210, 0.15) !important;
}

.inputBox :global(.MuiAutocomplete-option.Mui-focused),
.inputBox :global(.MuiAutocomplete-option:hover) {
    background-color: rgba(255, 255, 255, 0.08) !important;
}

/* Highlighted text in options */
.inputBox :global(.MuiAutocomplete-option span[style*="font-weight: 700"]) {
    color: #90caf9;
}

/* Input scrollbar styling */
.inputBox :global(.MuiInputBase-input::-webkit-scrollbar) {
    width: 8px;
    height: 8px;
}

.inputBox :global(.MuiInputBase-input::-webkit-scrollbar-track) {
    background-color: #1e1e1e;
}

.inputBox :global(.MuiInputBase-input::-webkit-scrollbar-thumb) {
    background-color: #404040;
    border-radius: 4px;
}

.inputBox :global(.MuiInputBase-input::-webkit-scrollbar-thumb:hover) {
    background-color: #666666;
}

